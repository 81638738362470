/* External dependencies */
import gql from 'graphql-tag';

export const getUserQuery = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      id
      lastName
      firstName
      patronymic
      gender
      birthDate
      disabledDate
      enabledDate
      accounts {
        id
        balance {
          amount
          currency
        }
        name
      }
      identificationStatus
      identificationDocument {
        id
        documentIdentificationStatus
        faceIdentificationStatus
        personalData {
          pin
          documentNumber
          front
          back
          selfie
        }
        actionHistory {
          # operator
          comment
          createdDate
          documentIdentificationStatus
          faceIdentificationStatus
          blackListIdentificationStatus
        }
      }
      joinDate
      phone
      groupName
      settings {
        lastNotificationDate
      }
      status
    }
  }
`;

export const getRootUserQuery = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      id
      lastName
      firstName
      accounts {
        id
        balance {
          amount
          currency
        }
        name
      }
      phone
      groupName
      status
      identificationDocument {
        id
        documentIdentificationStatus
        faceIdentificationStatus
        personalData {
          pin
          documentNumber
          front
          back
          selfie
        }
      }
    }
  }
`;
